/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './h3.module.scss'

export const H3: React.FC<PropsWithChildren> = ({ children }) => {
    return <h3 className={styles.h3}>{children}</h3>
}
