/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './text.module.scss'

export const Text: React.FC<PropsWithChildren & {title?: boolean;gray?: boolean}> = ({ children , title = false, gray = false}) => {
    return <p className={`${styles.text} ${title ? styles.red : ""} ${gray ? styles.gray : ""}`}>{children}</p>
}


